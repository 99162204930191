:root {
  --bg-color: #EAF1FF;;
  --accent: #2662F5;
  --white: #fff;
  --darkaccent2: #a50f0e;
  --black: #1A1B20;
  --bs-gutter-x: 3rem;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: 'DM Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.4;
  margin: 0;
  padding: 0;
}

.h1,
.h2 {
  font-size: 3rem;
}

.h3 {
  font-size: 2rem;
}

.h4 {
  font-size: 1.75rem;
}

.h5 {
  font-size: 1.5rem;
}

.h6 {
  font-size: 1.25rem;
}

img {
  max-width: 100%;
}

p {
  font-size: 1rem;
  line-height: 150%;
  font-weight: 300;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.container {
  max-width: 100%;
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width:1400px) {
    max-width: 1320px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
  }
}

.mainWrapper {
  header {
    padding: 1rem 0;
    display: none;

    @media (min-width: 992px) {
      padding: 1rem 0;
      display: none;
    }

    position: relative;
    z-index: 2;

    .logo-wrapper {
      a {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .hero-section {
    padding: 5rem 0 0 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    &::after {
      content: "";
      background: var(--Hero-G2, linear-gradient(158deg, #536EFF -1.67%, #C6C9FF 61.19%, #23FFFF 136.79%));
      height: 40%;
      width: 100%;
      bottom: 0;
      position: absolute;
      left: 0;
      @media (min-width: 992px) {
        left: auto;
        height: 100%;
        right: 0;
        width: 40%;
      }
    }
    @media (min-width: 992px) {
      padding-top: 0px;
      text-align: left;
    }

    .container {
      padding: 0;
      position: relative;
      z-index: 2;

      @media (min-width: 992px) {
        padding: 0 0.5rem;

      }

      .row {
        min-height: 50vh;
        align-items: center;
        justify-content: center;

        //padding: 0 1rem;
        @media (min-width: 992px) {
          align-items: center;
          align-content: flex-end;
        }

        .hero-container {
          padding: 0 1rem;
          border-radius: 8px;
          width: 100%;
          flex: 0 0 auto;

          @media (min-width: 992px) {
            width: 50%;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--black);
              letter-spacing: -0.3px;
              text-transform: capitalize;
            }
          }

          .sub-hero-section {
            .hero-section-wrapper {
              .hero-section-wrapper-content {
                h2 {
                  font-size: 30px;
                  line-height: 1.33;
                  margin-bottom: 1rem;
                  color: var(--black);

                  @media (min-width: 992px) {
                    font-size: 2.5rem;
                  }
                }

                p {
                  font-size: 16px;
                  margin-bottom: 1rem;
                  color: var(--black);

                  @media (min-width: 992px) {
                    font-size: 18px;
                  }
                }
              }

              .hero-sectiom-image {
                margin-bottom: 1rem;
              }

              .cta {
                text-align: center;
                @media (min-width: 992px) {
                  text-align: left;
                }
                a {
                  position: relative;
                  z-index: 0;
                  overflow: hidden;
                  border-radius: 10px;
                  padding-top: 13px;
                  padding-bottom: 13px;
                  padding-left: 40px;
                  padding-right: 40px;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 1.5rem;
                  letter-spacing: -0.3px;
                  background: var(--button1, linear-gradient(92deg, #677EFF 4.1%, #9DA9FF 94.32%));
                  color: var(--white);
                  display: inline-block;

                  &:hover,
                  &:focus {
                    background-color: var(--darkaccent2);
                  }
                }

                span {
                  margin-top: .5rem;
                  display: block;
                  color: var(--accent);
                  font-weight: 700;

                  @media (min-width: 992px) {
                    //margin-top: 1rem;
                    text-align: left;
                  }

                  font-size: 1rem;
                }
              }
            }
          }
        }

        .card-container {
          margin-top: 2rem;
          position: relative;

          @media (min-width: 992px) {
            text-align: left;
            margin-top: 0;
            width: 50%;
            padding: 0;
          }

          .mainbg {
            position: relative;

            img {
              max-width: 100%;

              
            }
          }
        }
        .question-item {
          padding: 0 1rem;
          .congrats {
            max-width: 580px;
            width: 100%;
            background-color: var(--bg-color);
            padding: 1.25rem;

            @media (min-width: 992px) {
              padding: 2rem;
            }

            border-radius: 18px;
            text-align: center;

            h1 {
              font-size: calc(1.375rem + 1.5vw);
              line-height: 1.5;

              @media (min-width: 992px) {
                font-size: 2.5rem;
              }
            }

            h2 {
              color: #0058FF;
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              line-height: 120%;
            }

            a {
              display: block;
              margin: 1rem 0;
              background: var(--button1, linear-gradient(92deg, #677EFF 4.1%, #9DA9FF 94.32%));
              color: #fff;
              font-weight: bold;
              text-decoration: none;
              padding: 1rem;
              font-size: 1.5rem;
              border-radius: 8px;
              box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
              transition: all .2s ease-in-out;

              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }

            p {
              span {
                color: #0058FF;
                font-weight: bold;
              }
            }

            img {
              margin-top: 1rem;
            }
          }
        }
        .question-wrapper {
          flex: auto;
          width: 100%;
          background-color: var(--bg-color);
          margin: 0 1rem;
          padding: 1.5rem;
          border-radius: 10px;

          @media (min-width:992px) {
            margin: 0;
            padding: 2.5rem;
            max-width: 600px;
          }

          .headline-wrapper {
            h1 {
              font-size: 1rem;
              font-weight: 600;
              margin-bottom: 10px;
              color: var(--accent);
              letter-spacing: -0.3px;
              text-transform: capitalize;
              text-align: center;
            }
          }

          .question-item {
            .question {
              position: relative;
              text-align: center;

              @media (min-width: 992px) {
                padding: 0;
                background-color: transparent;
                box-shadow: none;
                margin: 0;
              }

              border-radius: 18px;
              margin: 1rem auto;
              max-width: 580px;
              width: 100%;

              h2 {
                font-size: 25px;
                font-weight: 700;

                @media (min-width:1200px) {
                  font-size: 2rem;
                }
              }

              a {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 1rem 0;
                background: var(--button1, linear-gradient(92deg, #677EFF 4.1%, #9DA9FF 94.32%));
                color: #fff;
                font-weight: bold;
                text-decoration: none;
                padding: 0 1rem;
                font-size: 18px;
                border-radius: 8px;
                box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.07);
                height: 50px;
              }
            }

          }

          h1 {
            color: #fff;
          }
        }
      }
    }
  }

  .card-section {
    position: relative;
    z-index: 5;
    padding: 2rem 0;
    background-color: var(--bg-color);
    backdrop-filter: blur(15px);

    .container {
      .row {
        justify-content: center;

        .custom-container {
          width: 100%;

          .icon-pack {
            margin-top: 1rem;
            @media (min-width: 992px) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            }
            .headline-back {
              text-align: center;
              flex: 0 0 auto;
              width: 100%;
              @media (min-width: 992px) {
                width: 30%;
              }
              h2 {
                font-size: 1rem;
                font-weight: 600;
                letter-spacing: 2px;
                @media (min-width: 992px) {
                  font-size: 1.5rem;
                }
              }
            }

            .logo-icon-pack {
              max-width: 38.75rem;
              margin: 1rem auto;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-around;
              gap: 0.5rem;

              @media (min-width: 992px) {
                width: 70%;
                margin: 0;
                flex: 0 0 auto;
                max-width: 100%;
              }

              img {
                flex: 0 0 auto;
                width: 25%;
                margin-bottom: 0.25rem;

                @media (min-width: 992px) {
                  width: 15%;
                }
              }
            }
          }
        }
      }
    }
  }

  .section1 {
    padding: 3rem 0;

    .container {
      .row {
        align-items: center;

        .section-1-content {
          flex: 0 0 auto;
          width: 100%;
          margin: 0 auto;

          @media (min-width:992px) {
            width: 50%;
            padding: 0 1rem;
            margin: 0 auto;
            text-align: center;
          }

          h1 {
            margin-bottom: 1rem;
            line-height: 120%;
            font-size: 30px;

            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }

          h2 {
            font-size: calc(1.275rem + .3vw);
            font-weight: 500;

            @media (min-width: 1200px) {
              font-size: 1.5rem;
            }
          }

          .cta-holder {
            max-width: 580px;
            margin: 1rem 0;

            a {
              position: relative;
              z-index: 0;
              overflow: hidden;
              border-radius: 10px;
              padding-top: 13px;
              padding-bottom: 13px;
              padding-left: 40px;
              padding-right: 40px;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.5rem;
              letter-spacing: -0.3px;
              background: var(--button1, linear-gradient(92deg, #677EFF 4.1%, #9DA9FF 94.32%));
              color: var(--white);
              display: inline-block;

              &:hover,
              &:focus {
                background-color: var(--darkaccent2);
              }
            }
          }
        }

        .item-benefits {
          margin: 2rem 0;
          width: 100%;

          @media (min-width:992px) {
            width: 100%;
            padding: 0 1rem;
          }

          ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
              flex: 0 0 auto;
              width: 100%;
              margin-bottom: 1rem;

              @media (min-width:992px) {
                max-width: 24%;
                margin: 0 .25rem;
                min-height: 257px;
              }

              .benefit {
                height: 100%;
                border-radius: 10px;
                background-color: var(--bg-color);
                position: relative;
                padding: 28px;

                .benefitImage {
                  flex: 0 0 auto;
                  position: relative;
                  margin-bottom: 1rem;
                }

                .benefitHeadline {
                  flex: 0 0 auto;
                  display: flex;
                  flex-wrap: wrap;

                  h3 {
                    font-size: 20px;
                    font-weight: 700;
                    display: block;
                    margin-bottom: 1rem;
                  }

                  p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }
    }

    @media (min-width: 992px) {
      padding: 4rem 0;
    }
  }

  .section2 {
    padding: 3rem 0;

    @media (min-width:992px) {
      padding: 5rem 0;
    }
    .container {
      .row {
        .section2-headline-container {
          width: 100%;

          @media (min-width:992px) {
            width: 50%;
            margin-bottom: 2rem;
          }

          h1 {
            margin-bottom: 1rem;
            line-height: 120%;
            font-size: 30px;

            @media (min-width: 992px) {
              font-size: 2.5rem;
            }
          }

          p {
            margin-bottom: 2rem;
          }
        }

        .section2-stat-counter {
          width: 100%;

          @media (min-width:992px) {
            display: flex;
            gap: 1rem;
            align-items: center;
          }

          .statCounter {
            margin-bottom: 1.5rem;

            @media (min-width:992px) {
              .statCounter {
                width: 25%;
              }
            }

            .statCounterStats {
              h3 {
                color: #0058FF;
                font-size: 24px;
              }

              span {
                font-size: 20px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  }

  .testimonial {
    padding: 3rem 0;
    text-align: center;

    @media (min-width: 992px) {
      padding: 4rem 0;
    }

    img {
      max-width: 100%;

      @media (min-width: 992px) {
        max-width: 580px;
      }
    }

    .testimonial-headline {
      flex: 0 0 auto;
      width: 100%;

      h1 {
        margin-bottom: 1rem;
        line-height: 120%;
        font-size: calc(1.375rem + 1.5vw);
        @media (min-width: 1200px) {
          font-size: 2.5rem;
        }
      }
    }

    .testimonial-content-holder {
      flex: 0 0 auto;
      width: 100%;

      h3 {
        font-weight: 500;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    .testimonial-list {
      display: flex;
      margin-top: 2rem;
      flex-wrap: wrap;

      .testimonial-item {
        margin-bottom: 1rem;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 1.5rem;

        @media (min-width: 992px) {
          width: 33%;
          padding: 0 1rem;
        }

        .testimonial-inner {
          padding: 40px 30px;
          text-align: left;
          border-radius: 10px;
          background-color: var(--bg-color);

          .testimonial-bottom {
            margin-bottom: 16px;
          }

          .testimonial-top {
            display: flex;
            align-items: center;

            .star-rating {
              display: flex;
              margin-bottom: 16px;

              img {
                width: 130px;
              }
            }

            .userimg {
              max-width: 35px;
              border-radius: 100%
            }

            h3 {
              font-size: 18px;
              font-weight: 500;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .footer {
    padding: 4rem 0 6rem 0;

    .top-footer {
      p {
        font-size: .875rem;
      }
    }

    .bottom-footer {
      .footer-navigation {
        flex: 0 0 auto;
        width: 100%;
        margin: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        @media(min-width:992px) {
          justify-content: space-between;
        }

        .footer-copyright {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }
        }

        .footer-navigation-wrapper {
          flex: 0 0 auto;
          width: 100%;

          @media(min-width:992px) {
            width: auto;
          }

          ul {
            display: flex;
            justify-content: center;
            align-items: center;

            li {
              &:nth-child(1) {
                &::after {
                  content: '|';
                  margin: 0 1rem;
                }
              }

              a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .policy-wrapper {
    .policy-title {
      padding: 2rem 0;

      h1 {
        font-size: calc(1.375rem + 1.5vw);

        @media (min-width:1200px) {
          font-size: 2.5rem;
          ;
        }
      }

      flex: 0 0 auto;
      width: 100%;
      border-bottom: 1px solid gray;
      margin-bottom: 2rem;
    }

    .policy-paragraph {
      padding-bottom: 4rem;

      p {
        margin-bottom: .5rem;
      }

      h2 {
        font-size: calc(1.325rem + .9vw);
        margin: 1rem 0;

        @media(min-width:1200px) {
          font-size: 2rem;
        }
      }

      h3 {
        font-size: calc(1.3rem + .6vw);
        margin-bottom: 1rem;

        @media(min-width:1200px) {
          font-size: 1.75rem;
        }
      }

      h3 {
        font-size: calc(1.275rem + .3vw);

        @media(min-width:1200px) {
          font-size: 1.5rem;
        }
      }

      ul {
        list-style-type: disc;
        margin-bottom: .5rem;
      }
    }
  }
}